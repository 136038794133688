import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const alertsConfigs = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/alerts',
        component: React.lazy(() => import('./Alerts/alertsList')),
        title: 'Alerts',
      }
    ],
  }
];
