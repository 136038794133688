import {
  GET_CUSTOMER_LIST,
  SET_SELECTED_CUSTOMER,
  SET_PERMISSION_MODAL_VIEW,
  STRIPE_INFO_MODAL_VIEW,
  SET_CUSTOMER_EXPORT_FILTER,
  SET_REACH_CUSTOMER_FILTER,
  SET_BLOCKED_DOMAIN_FILTER,
  SET_CUSTOMER_ABUSE_FILTER,
  SET_INSIDE_PRODUCT_FILTER,
  SET_OUTSIDE_TECHNOLOGY_FILTER,
  SET_MANAGE_API_KEYS_FILTER,
  SET_APPROVE_FILTERS,
  SET_RESTORE_CUSTOMER_CART_FILTERS,
  SET_AGENT_FILTERS,
  SET_AGENTDATA_TO_EDIT,
  SET_TICKET_SUPPORT_FILTER,
  SET_LATEST_SUPPORT_TICKET_COUNT,
  SET_ALERT_BADGE_COUNT,
  SET_PROSPECT_FILTERS,
  SET_CUSTOMERS_FILTERS,
  SET_CUSTOMER_ORDERS,
  SET_LOGS_DATA,
  SET_GMAIL_AUTH_ACCOUNTS_DATA,
  SET_ZERO_ACTIVITY_FILTERS
} from '../../shared/constants/ActionTypes';

export default (state, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST:
      return {
        ...state,
        customersList: action.payload,
      };
    case SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomerObject: action.payload,
      };
    case SET_PERMISSION_MODAL_VIEW:
      return {
        ...state,
        isOpenPermModel: action.payload,
      };

    case STRIPE_INFO_MODAL_VIEW:
      return {
        ...state,
        isOpenStripeInfoModel: action.payload,
      };
    case SET_CUSTOMER_EXPORT_FILTER:
      return {
        ...state,
        customerExportFilter: action.payload,
      };
    case SET_REACH_CUSTOMER_FILTER:
      return {
        ...state,
        reachCustomerFilter: action.payload,
      };
    case SET_BLOCKED_DOMAIN_FILTER:
      return {
        ...state,
        blockedSearchString: action.payload,
      };
    case SET_CUSTOMER_ABUSE_FILTER:
      return {
        ...state,
        customerAbuseFilter: action.payload,
      };
    case SET_INSIDE_PRODUCT_FILTER:
      return {
        ...state,
        insideProductSearchString: action.payload,
      };
    case SET_OUTSIDE_TECHNOLOGY_FILTER:
      return {
        ...state,
        outsideTechnologySearchString: action.payload,
      };
    case SET_MANAGE_API_KEYS_FILTER:
      return {
        ...state,
        manageApiKeysFilter: action.payload,
      };
    case SET_APPROVE_FILTERS:
      return {
        ...state,
        approveFilters: action.payload,
      };
    case SET_RESTORE_CUSTOMER_CART_FILTERS:
      return {
        ...state,
        restoreCustomerCartFilter: action.payload,
      };
    case SET_AGENT_FILTERS:
      return {
        ...state,
        agentFilter: action.payload,
      };
    case SET_AGENTDATA_TO_EDIT:
      return {
        ...state,
        agentDataToEdit: action.payload,
      };
    case SET_TICKET_SUPPORT_FILTER:
      return {
        ...state,
        supportTicketFilter: action.payload,
      };
    case SET_LATEST_SUPPORT_TICKET_COUNT:
      return {
        ...state,
        latestSupportTicketCount: action.payload,
      };
    case SET_ALERT_BADGE_COUNT:
      return {
        ...state,
        alertBadgeCount: action.payload,
      };
    case SET_PROSPECT_FILTERS:
      return {
        ...state,
        prospectFilter: action.payload,
      };
    case SET_ZERO_ACTIVITY_FILTERS:
      return {
        ...state,
        zeroActivityFilter: action.payload,
      };
    case SET_CUSTOMERS_FILTERS:
      return {
        ...state,
        customersSearch: action.payload,
      };
    case SET_CUSTOMER_ORDERS:
      return {
        ...state,
        customer_orders: action.payload,
      };
    case SET_LOGS_DATA:
      return {
        ...state,
        logsData: action.payload,
      };
    case SET_GMAIL_AUTH_ACCOUNTS_DATA:
      return {
        ...state,
        gmailAuthAccountsData: action.payload,
      };
    default:
      return state;
  }
};
