import React, {useState, useContext } from 'react';
import {makeStyles, Grid} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {searchMembers} from '../../../@crema/services/auth/jwt-auth/apiCalls';

//import Toolbar from '@material-ui/core/Toolbar';
import {useHistory, useLocation} from 'react-router-dom';

import {CustomersContext} from '../../../provider/CustomerContextProvider';

const useStyles = makeStyles((theme) => ({
  normalSearch: {},
  headerSearch: {
    width: 300,
    float: 'right',
  },
}));

//const scrollToArray = ['My Account', 'Sub members', 'Triggers', 'News Configuration'];
function SearchMemberAutoComplete(props) {
  const classes = useStyles();
  let nooptionText = 'Enter Member First/Last Name, Email, Company Name, Phone';
  const [optionsObj, setOptions] = useState([]);
  const [searchedStr, setSearchedStr] = useState("");
  const [Value, setValue] = useState(null);

  //const [open, setOpen] = useState(false);
  //const [toastmessage, setToastmessage] = useState('');
  // const [toastclass, setToastclass] = useState('success');
  const [autocompleteIsLoading, setautocompleteIsLoading] = useState(false);
  const [searchValue, setsearchValue] = useState(nooptionText);
  // const [isCustomerCreateOpen, setisCustomerCreateOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [customerProviderState, dispatch] = useContext(CustomersContext);

  //      if(!props.isIncludedInHeader && props.route){
  //        document.title = props.route.title;
  //      }

  const handleChange = async (selectedCustomerObject) => {
    if (selectedCustomerObject !== null) {
      if (selectedCustomerObject.customer_id > 0) {
        dispatch({
          type: 'SET_SELECTED_CUSTOMER',
          payload: selectedCustomerObject,
        });

        if (
          location.pathname !== '/editmember' ||
          location.pathname !== '//create-new-customer'
        ) {
          history.push('/editmember');
        }
      }
    } else {
      setValue(null);

      dispatch({type: 'SET_SELECTED_CUSTOMER', payload: null});
    }
  };

  const handleInputChange = async (searchString) => {

    setSearchedStr(searchString);
    setautocompleteIsLoading(true); //Loading set true
    setsearchValue('No Record found with ' + searchString); //After request complete if retuen empty data then this message will be set
    const customersData = await searchMembers(searchString); //Sending request to the API
    if (typeof customersData === 'object' && customersData !== null) {
      setOptions(customersData); //Setting Autocomplete dropdown options
      setautocompleteIsLoading(false);
    } else if (customersData === undefined) {
      //dispatch({type: 'SET_SELECTED_CUSTOMER', payload: null});
      //handle 204 empty response
    } else {
      setautocompleteIsLoading(false); //Afetr response recieved set loading false
      // setToastmessage(customersData);
      //toastNotification(customersData);
      // setToastclass("error");
      // setOpen(true);
    }
  };
  const clearData = (e) => {
    setsearchValue(nooptionText);
  };

  return (
    <React.Fragment>
      <Grid item xs={!props.isIncludedInHeader ? 8 : 12}>
        <Autocomplete
          onClose={(e) => clearData(e)}
          options={ optionsObj }
          getOptionLabel={ (option) => {
              let stringArr = searchedStr?.split("."); // check dots in string              
              let is_ipv6 = searchedStr?.split(":"); // check colans in string 
              var numb = Number(searchedStr.replaceAll('.', ''));
              // console.log((stringArr?.length, !isNaN(numb), option?.ip, option?.ip !== undefined, (is_ipv6?.length > 0)), stringArr?.length, !isNaN(numb), option?.ip !== "", option?.ip !== undefined, is_ipv6?.length );
              if(searchedStr.indexOf("cus_") > -1){
                return `${option?.first_name} ${option?.last_name} - ${option?.email} - (${option?.stripe_id || ""})`;
              } else if((stringArr?.length > 1 && !isNaN(numb) && option?.ip !== "" && option?.ip !== undefined) || (is_ipv6?.length > 1) ){
                return `${option?.first_name} ${option?.last_name} - ${option?.email} - (${option?.ip || ""})`;
              } else {
                return `${option?.first_name} ${option?.last_name} - ${option?.email}`;
              }
            }
          }
          value={Value}
          onChange={(event, newValue) => handleChange(newValue)}
          onKeyUp={(e) => handleInputChange(e.target.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search For Member . . .'
              variant='outlined'
              small="medium"
            />
          )}
          noOptionsText={searchValue}
          loadingText='Loading...'
          loading={autocompleteIsLoading}
          className={
            props.isIncludedInHeader
              ? classes.headerSearch
              : classes.normalSearch
          }
        />
      </Grid>
    </React.Fragment>
  );
}
export default SearchMemberAutoComplete;
