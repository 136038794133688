export const authRole = {
  admin: ['su'],
  user: ['su','manager','sales','data_entry','company_size'],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'su',
  photoURL: 'https://via.placeholder.com/150',
};
export const initialUrl = '/editmember'; // this url will open after login
export const initialUrlDataEntery = '/dataentry/createEditCompany'; // this url will open after login
