import {
  CHANGE_READ_STATUS,
  COMPOSE_MAIL,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CONNECTION_LIST,
  GET_FOLDER_LIST,
  GET_LABEL_LIST,
  GET_MAIL_DETAIL,
  GET_MAIL_LIST,
  NULLIFY_MAIL,
  SHOW_MESSAGE,
  TOGGLE_MAIL_DRAWER,
  UPDATE_MAIL_FOLDER,
  UPDATE_MAIL_LABEL,
  UPDATE_STARRED_STATUS,
  UPDATED_MAIL_DETAIL,
} from '../../shared/constants/ActionTypes';
import Api from '@crema/services/ApiConfig';
// import { mailListMessages } from '@crema/services/db/apps/mail/folderList';
import { appIntl } from '@crema/utility/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
var retry = 0;
let maxRetry = 2;

export const onGetMailList = (id, type, name, nextPageToken) => {

  const { messages } = appIntl();
  const page = nextPageToken ? nextPageToken : null;

  return (dispatch) => {

    dispatch({type: FETCH_START});
    let payload = {
                    label: name,
                    id: id,
                    next_page_token: page
                  }

    callAPI("getEmailInbox", payload)
      .then((data) => {
       
        if (data.success) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MAIL_LIST, payload: { list: data?.data?.messages, total: 10, nextPageToken: data?.data?.next_page_token || "" } });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }

      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      })
    
  };
};

export const onGetLabelList = ( payload ) => {

  const {messages} = appIntl();
  return (dispatch) => {

    dispatch({type: FETCH_START});
    callAPI("email/labels/list", payload)
              .then((data) => {

                console.log(data, " data >>> ");

                if (data.success) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({type: GET_LABEL_LIST, payload: data.data});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: messages['message.somethingWentWrong'],
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
              });    
      
  }

  /*return (dispatch) => {
    dispatch({type: FETCH_START});

    Api.get('/api/mailApp/labels/list')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_LABEL_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });

  };*/

};

export const onToggleMailDrawer = () => {
  return (dispatch) => {
    dispatch({type: TOGGLE_MAIL_DRAWER});
  };
};

export const onGetFolderList = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/mailApp/folders/list')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_FOLDER_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetConnectionList = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/mailApp/connection/list')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CONNECTION_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateMailLabels = (mailIds, type) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/mailApp/update/label', {mailIds, type})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_MAIL_LABEL, payload: data.data});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.labelUpdatedTo'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateStarredStatus = (mailIds, status, folderName) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/mailApp/update/starred', {mailIds, status})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: UPDATE_STARRED_STATUS,
            payload: {data: data.data, folderName: folderName},
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.starredStatus'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateMailFolders = async (mailIds, type) => {

  

  /*const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/mailApp/update/folder', {mailIds, type})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_MAIL_FOLDER, payload: data.data});
          dispatch({
            type: SHOW_MESSAGE,
            payload: mailListMessages(type),
            // payload:messages['mail.updated' />,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };*/
};

export const onUpdateReadStatus = (mailIds, status) => {
  const {messages} = appIntl();
  return (dispatch) => {
    /*dispatch({type: FETCH_START});
    Api.put('/api/mailApp/update/read', {mailIds, status})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CHANGE_READ_STATUS, payload: data.data});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.readStatus'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });*/
  }
}

export const onGetSelectedMail = (id, threadId) => {

  const {messages} = appIntl();
  let payload = { id, threadId  }
  return (dispatch) => {

    dispatch({type: FETCH_START});
    callAPI("email/get_single_email", payload)
      .then((data) => {
        
        // console.log(data, " data.status ");
        if (data.success === true) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MAIL_DETAIL, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
        
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateSelectedMail = (mail) => {
  /*const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/mailApp/mail/', {mail})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATED_MAIL_DETAIL, payload: data.data});
          dispatch({
            type: SHOW_MESSAGE,
            payload: mailListMessages(mail.folderValue),
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };*/
};

export const onDraftMailUpdate = (mail, pathname) => {

  console.log(" >>> ", mail, pathname);

  const {messages} = appIntl();

  return (dispatch) => {

      dispatch({type: FETCH_START});
      callAPI("gmail_update_draft_mail", mail)
      .then((data) => {

        console.log(data, " data  >>> ");
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          // dispatch({
          //   type: COMPOSE_MAIL,
          //   payload: {data: data.data, pathname: pathname},
          // });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['mail.sentSuccessfully'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }

      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });     
    }

}

export const onComposeMail = async (mail, pathname) => {

  console.log(" >>> ", mail, pathname);

  const {messages} = appIntl();

  return (dispatch) => {

      dispatch({type: FETCH_START});
      callAPI("gmail_create_draft_mail", mail)
      .then((data) => {

        console.log(data, " data  >>> ");
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: COMPOSE_MAIL,
            payload: {data: data.data, pathname: pathname},
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['mail.sentSuccessfully'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }

      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });     
    }

}

export const onNullifyMail = () => {
  return {
    type: NULLIFY_MAIL,
  };
};

async function callAPI(endpoint, body) {
  retry = 0;
  return await APITrigger(endpoint, body);
}

const APITrigger = async (endpoint, body) => {

  let timeoutMillisec = 60000;
  try {
        const res = await jwtAxios.post( endpoint, body, {timeout: timeoutMillisec});
        return res.data;
  } catch (err) {

        if(err.response && err.response.statusText === "Unauthorized"){
          delete jwtAxios.defaults.headers.common['Authorization'];
          localStorage.removeItem('token');
          window.location.reload();
        }
        
        //console.log(err.message);
        
        if((err.message === "timeout of "+timeoutMillisec+"ms exceeded") || (err.message === "Network Error") && retry < maxRetry){
            console.log(retry +" < "+ maxRetry);
            retry++;
            return APITrigger(endpoint, body);
        }
        return err.message;
        
  }  

}
