import React, {useState} from 'react';
//import { useAuthUser } from '@crema/utility/AppHooks';
import {Grid, Button, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {agentChangePassword} from '../../../@crema/services/auth/jwt-auth/apiCalls';
import ToastNotification from '../../toastNotification/toastNotification';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root, & .MuiSelect-root': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: '100%',
      '& input': {
        background: '#fff',
      },
    },
    '& .error': {
      color: 'red',
    },
  },
}));

function AgentResetPasswordForm(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [responseToast, setResponseToast] = useState(null);
  const [changePasswordErrors, setChangePasswordErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setOpen(false);
    // var formname = event.target.name;
    const formData = new FormData(e.target);
    var payload = {};
    for (let [key, value] of formData.entries()) {
      payload[key] = value;
    }

    let errorsObj = {oldPassword: '', newPassword: '', confirmNewPassword: ''};

    // submembersformErrors
    let isValid = true;
    let oldPassword = payload.old_password;
    let newPassword = payload.new_password;
    let confirmNewPassword = payload.confirm_new_password;

    if (oldPassword.length < 1) {
      errorsObj.oldPassword = 'Please enter your old/current password';
      isValid = false;
    }

    if (newPassword.length < 6) {
      errorsObj.newPassword = 'Please enter min 6 character password';
      isValid = false;
    }

    if (confirmNewPassword !== newPassword) {
      errorsObj.confirmNewPassword =
        'New Password and Confirm New password are not matched';
      isValid = false;
    }

    setChangePasswordErrors(errorsObj);

    if (isValid) {
      const response = await agentChangePassword(payload);
      //console.log(response);
      setResponseToast(response);
      setOpen(true);
    }
  };

  return (
    <>
      <form
        name='packageUpdate'
        onSubmit={handleFormSubmit}
        noValidate
        className={classes.root}
        autoComplete='off'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2>Change Password</h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Old Password'
              name='old_password'
              variant='outlined'
            />
            {changePasswordErrors.oldPassword.length > 0 ? (
              <span className='error'>{changePasswordErrors.oldPassword}</span>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='New Password'
              name='new_password'
              variant='outlined'
            />
            {changePasswordErrors.newPassword.length > 0 ? (
              <span className='error'>{changePasswordErrors.newPassword}</span>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Confirm New Password'
              name='confirm_new_password'
              variant='outlined'
            />
            {changePasswordErrors.confirmNewPassword.length > 0 ? (
              <span className='error'>
                {changePasswordErrors.confirmNewPassword}
              </span>
            ) : null}
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              size='large'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {open ? <ToastNotification response={responseToast} /> : null}
    </>
  );

  // const [authUser] = useAuthUser();
}

export default AgentResetPasswordForm;
