import React, {createContext, useReducer, useEffect} from 'react';

import customers from '../redux/reducers/Customers';

const initialState = {
  customersList: [],
  selectedCustomerObject: 0,
  isOpenPermModel: false,
  customerExportFilter: {email_status: 0, fromDate: null, toDate: null},
  reachCustomerFilter: {email_status: 0, fromDate: null, toDate: null},
  blockedSearchString: {queryCompany: null},
  customerAbuseFilter: {search_query: null, last_x_days: 30 },
  insideProductSearchString: {search_query: null},
  outsideTechnologySearchString: {search_query: null},
  manageApiKeysFilter: {search_query: null, status: ''},
  approveFilters: {search_query: '', selected_approve_type: 1},
  restoreCustomerCartFilter: {search_query: ''},
  agentFilter: {search_query: ''},
  agentDataToEdit: {},
  supportTicketFilter: { fiter_prospect_date: 1,request_type: 'All' },
  latestSupportTicketCount: false,
  alertBadgeCount: false,
  prospectFilter: {search_query: ""},
  zeroActivityFilter: { button_status: "needs_attention" },
  customersSearch: {ip: "0.0.0.0.0", ipLocation: "", model: false}
};

export const CustomersContext = createContext();

const CustomerContextProvider = ({children}) => {
  const [customerProviderState, dispatch] = useReducer(customers, initialState);

  useEffect(() => {
    //           console.log("inside stateemployeecompanycontext");
    //           console.log(statePost);
    //console.log(children);
  }, []);

  return (
    <CustomersContext.Provider value={[customerProviderState, dispatch]}>
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomerContextProvider;
