import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import { useLocation } from 'react-router-dom';
import routesConfig from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import { checkPermission } from "@crema/utility/Utils";
import AppContext from '@crema/utility/AppContext';
import { useAuthToken } from '@crema/utility/AppHooks';
import {matchRoutes} from 'react-router-config';

const Navigation = () => { 

  const {pathname, search} = useLocation();
  const { routes } = useContext(AppContext);
  const [loading, user] = useAuthToken(); 
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let routesConfigCopy = [...routesConfig];
 
  return (
    <List>
      {routesConfigCopy.map((item) => {

            // console.log(item, item?.type);
            let isAllow = checkPermission( (pathname === "/permission-denied" || pathname === "/signin") ? ['su','manager','sales','data_entry','company_size'] : currentRoute.auth, user ? user.role : null, user ? user.permissions: null, { path: item?.url ? item?.url : "" });
            if (item?.type === 'collapse') {
                let childrens = item?.children?.filter((itm) => {
                                                                  let isChildrenAllowed = checkPermission((pathname === "/permission-denied") ? ['su','manager','sales','data_entry','company_size'] : currentRoute.auth, user ? user?.role : null, user ? user?.permissions: null, { path: itm?.url ? itm?.url : "" });
                                                                  if(isChildrenAllowed){
                                                                    return itm;
                                                                  } else {
                                                                    return false;
                                                                  }
                                                              });               
                item.children = childrens;
            }

            //console.log(isAllow, item);
            // console.log(item?.type, isAllow, item?.url);
            return (<React.Fragment key={item.id}>

                      {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

                      { ( item.type === 'collapse' && item.children?.length > 0 ) && (
                        <VerticalCollapse item={item} level={0} />
                      )}

                      { (item.type === 'item' && isAllow === true) && <VerticalItem item={item} level={0} />}

                    </React.Fragment>
                  )
          }
      
      )}
    </List>
  );
};

export default Navigation;
