import React, {createContext, useReducer, useEffect} from 'react';

import custom from '../redux/reducers/Custom';

const initialState = {
  companyEmployees: [{ first_name: "First Name",  middle: "Middle Name", last_name: "Last Name", title_main: "Title",
                       email: "Email", phone: "", extension: "", twitter: "Twitter", facebook: "Facebook", linkedin: "Linkedin" }]
};

export const CustomContext = createContext();

const CustomContextProvider = ({children}) => {
  const [customProviderState, dispatch] = useReducer(custom, initialState);
  return (
    <CustomContext.Provider value={[customProviderState, dispatch]}>
      {children}
    </CustomContext.Provider>
  );
};

export default CustomContextProvider;
