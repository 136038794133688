import {
  SET_COMPANY_EMPLOYEES,
  SET_ADD_EDIT_COMPANY_DATA,
  SET_COMPANY_LOGS_DATA,
  SET_LOGS_COMPANY_DATA,
  SET_EDIT_EDIT_PRESS_DATA,
  SET_PRESS_TEMPLATES,
  SET_EMPLOYEE_SAVE_ERROR
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  user: null,
  token: null,
};

const customReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_EMPLOYEES: {
      return {
        ...state,
        companyEmployees: action.payload,
      };
    }
    case SET_ADD_EDIT_COMPANY_DATA: {
      return {
        ...state,
        addEditCompanyData: action.payload,
      };
    }

    case SET_COMPANY_LOGS_DATA: {
      return {
        ...state,
        logsData: action.payload,
      };
    }
    case SET_LOGS_COMPANY_DATA: {
      return {
        ...state,
        logsData: action.payload,
      };
    }

    case SET_EDIT_EDIT_PRESS_DATA: {
      return {
        ...state,
        editPressData: action.payload,
      };
    }
    case SET_PRESS_TEMPLATES: {
      return {
        ...state,
        pressTemplates: action.payload,
      };
    }
    case SET_EMPLOYEE_SAVE_ERROR: {
      return {
        ...state,
        employeeSaveLastError: action.payload,
      };
    }
    default:
      return state;
  }
};
export default customReducer;
