import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {dashBoardConfigs} from './dashboard';
import {agentConfigs} from './manageAgents';
import {alertsConfigs} from './alerts';
import {customerAbuseCalculatedConfigs} from './customerAbuseCalculated';
import {userPagesConfig} from './userPages';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {menuLevelConfig} from './menu';
import {appsConfig} from './apps';

const routeConfigs = [
  ...authRouteConfig,
  ...dashBoardConfigs,
  ...agentConfigs,
  ...userPagesConfig,
  ...errorPagesConfigs,
  ...menuLevelConfig,
  ...appsConfig,
  ...alertsConfigs,
  ...customerAbuseCalculatedConfigs
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
