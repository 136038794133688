import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const dashBoardConfigs = [
  /**clepr code **/
  {
    //auth: ['user'],
    //role: ['admin'],
    auth: authRole.user,
    routes: [
      {
        path: '/editmember',
        component: React.lazy(() => import('./Members/editMember')),
        title: 'Edit Member',
      },
      {
        path: '/create-new-customer',
        component: React.lazy(() => import('./Members/editMember')),
        title: 'Create New Customer',
      },
      {
        path: '/new-customers',
        component: React.lazy(() => import('./Members/Prospects/prospects')),
        title: 'New Customer',
      },
      {
        path: '/customers-list',
        component: React.lazy(() => import('./Members/customersList')),
        title: 'Customers',
      },
      {
        path: '/ban-ip',
        component: React.lazy(() => import('./Reports/banIp')),
        title: 'Ban Ip Address',
      },
      {
        path: '/quickstats',
        component: React.lazy(() => import('./Reports/quickStats')),
        title: 'Quick Stats',
      },
      {
        path: '/enrichment_stats',
        component: React.lazy(() => import('./Enrichment/enrichmentCustomers')),
        title: 'Enrichment Stats',
      },
      {
        path: '/enrichment_approve',
        component: React.lazy(() => import('./Enrichment/enrichmentApprove')),
        title: 'Enrichment Approve',
      },
      {
        path: '/counts',
        component: React.lazy(() => import('./Reports/counts')),
        title: 'Counts',
      },
      {
        path: '/tracker',
        component: React.lazy(() => import('./Reports/tracker')),
        title: 'sidebar.usage.stats',
      },
      {
        path: '/latest_customer',
        component: React.lazy(() =>
          import('./Reports/LatestCustomer/latestCustomer'),
        ),
        title: 'sidebar.latest.customer',
      },
      {
        path: '/reach-customers',
        component: React.lazy(() =>
          import('./Reports/ReachCustomers/reachCustomers'),
        ),
        title: 'sidebar.reach.customer',
      },
      {
        path: '/stripe-stats',
        component: React.lazy(() => import('./Reports/stripeStats')),
        title: 'Stripe Stats',
      },
      {
        path: '/account-standing',
        component: React.lazy(() => import('./Reports/AccountStanding/accountStanding')),
        title: 'Account Standing',
      },
      {
        path: '/company-list',
        component: React.lazy(() => import('./Reports/Company/company')),
        title: 'Domain Verifier',
      },
      {
        path: '/company-update/:company_id?',
        component: React.lazy(() =>
          import('./Reports/Company/updateCompany'),
        ),
        title: 'sidebar.add-edit-company',
      },
      {
        path: '/search/blocked-domains',
        component: React.lazy(() =>
          import('./Reports/BlockedDomain/blockDomains'),
        ),
        title: 'sidebar.reports.blocked.domains',
      },
      {
        path: '/customer/abuse',
        component: React.lazy(() =>
          import('./Reports/CustomerAbuse/customerAbuse'),
        ),
        title: 'sidebar.reports.customer.abuse',
      },
      {
        path: '/link-customer-id',
        component: React.lazy(() => import('./LinkCustomerId/linkCustomerId')),
        title: 'sidebar.edit.linkCustomerId',
      },
      {
        path: '/add-inside-product',
        component: React.lazy(() =>
          import('./DataEntry/AddInsideProduct/addInsideProduct'),
        ),
        title: 'sidebar.edit.addinside.product',
      },
      {
        path: '/data-mismatch',
        component: React.lazy(() =>
          import('./DataEntry/DataMismatch/datamismatch'),
        ),
        title: 'sidebar.data-mismatch-company',
      },
      {
        path: '/dataentry2/data-mismatch',
        component: React.lazy(() =>
          import('./DataEntry/DataMismatch/datamismatch'),
        ),
        title: 'sidebar.data-mismatch-company',
      },
      // {
      //   path: '/employee-data-mismatch',
      //   component: React.lazy(() =>
      //     import('./DataEntry/DataMismatch/datamismatchEmployee'),
      //   ),
      //   title: 'sidebar.data-mismatch-employee',
      // },
      {
        path: '/outside-technology',
        component: React.lazy(() =>
          import('./DataEntry/AddOutsideTechnology/addOutSideTechnology'),
        ),
        title: 'sidebar.add-outside-technology',
      },
      {
        path: '/gmail',
        component: React.lazy(() =>
          import('./DataEntry/Gmail/gmail'),
        ),
        title: 'sidebar.gmail',
      },
      {
        path: '/gmail_drafted_records/:id?',
        component: React.lazy(() =>
          import('./DataEntry/GmailDraftedRecords/GmailDraftedCustomer'),
        ),
        title: 'sidebar.view_gmail',
      },
      {
        path: '/config_auto_banning',
        component: React.lazy(() =>
          import('./ConfigAutoBanning'),
        ),
        title: 'sidebar.config_auto_banning',
      },
      {
        path: '/gmail_account/:id?',
        component: React.lazy(() =>
          import('./DataEntry/Gmail/gmailAccount'),
        ),
        title: 'sidebar.view_gmail_account',
      },
      {
        path: '/authGmailRedirect',
        component: React.lazy(() =>
          import('./DataEntry/Gmail/authGmailRedirect'),
        ),
        title: 'sidebar.authGmailRedirect',
      },
      {
        path: '/dataentry/createEditCompany/:company_id?',
        component: React.lazy(() =>
          import('./DataEntry/dataEntry'),
        ),
        title: 'sidebar.add-edit-company',
      },
      {
        path: '/dataentry2/createEditCompany/:company_id?',
        component: React.lazy(() =>
          import('./DataEntry/dataEntry'),
        ),
        title: 'sidebar.add-edit-company',
      },
      {
        path: '/dataentry/press_news_entry',
        component: React.lazy(() => import('./DataEntry2/DataEntryTwo')),
        title: 'sidebar.edit.data.press_news_entry_2',
      },
      {
        path: '/dataentry/press_news_funding_financing_entry',
        component: React.lazy(() => import('./DataEntry2/ConfirmFundingFinancingNews')),
        title: 'sidebar.edit.data.press_news_funding_financing_entry',
      },
      {
        path: '/press_news_funding_insert_company',
        component: React.lazy(() => import('./DataEntry2/FundingFinancingCompanyInsert')),
        title: 'sidebar.edit.data.press_news_funding_insert_company',
      },
      {
        path: '/dataentry/createPress/:pressstaged_company_id?/:press_staged?',
        component: React.lazy(() =>
          import('./DataEntry/Press/createPress'),
        ),
        title: 'sidebar.add-edit-company-press',
      },
      {
        path: '/dataentry2/createPress/:pressstaged_company_id?/:press_staged?',
        component: React.lazy(() =>
          import('./DataEntry/Press/createPress'),
        ),
        title: 'sidebar.add-edit-company-press',
      },
      // {
      //   path: '/dataentry/update-employee-size',
      //   component: React.lazy(() =>
      //     import('./DataEntry/UpdateEmployeeSize/updateEmployeeSize'),
      //   ),
      //   title: 'sidebar.update-employee-size',
      // },
      {
        path: '/editCompanyIndustrySicEmployeeSize/:company_id?',
        component: React.lazy(() =>
          import('./DataEntry/CompanyIndustry/editCompanyIndustrySicEmployeeSize'),
        ),
        title: 'sidebar.editCompanyIndustrySicEmployeeSize',
      },
      {
        path: '/dataentry/addStagedPress',
        component: React.lazy(() =>
          import('./DataEntry/StagedPress/addStagedPress'),
        ),
        title: 'sidebar.add-staged-press',
      }, 
      // {
      //   path: '/dataentry/update_old_companies',
      //   component: React.lazy(() =>
      //     import('./DataEntry/SmallerCompanyUpdate/smallerCompanyTable'),
      //   ),
      //   title: 'sidebar.update_old_companies',
      // }, 
      {
        path: '/dataentry/viewStagedPress',
        component: React.lazy(() =>
          import('./DataEntry/StagedPress/viewStagedPress'),
        ),
        title: 'sidebar.view-staged-press',
      },
       {
         path: '/dataentry/searchNewsletterCompany',
         component: React.lazy(() =>
           import('./DataEntry/CompanyTodayPress/CompanyTodayPress'),
         ),
         title: 'sidebar.company-for-today-press',
       },
      {
        path: '/dataentry/showPressList/:company_id?',
        component: React.lazy(() =>
          import('./DataEntry/Press/pressEvents'),
        ),
        title: 'sidebar.company-all-press',
      },
      {
        path: '/customer-unlocks-report',
        component: React.lazy(() =>
          import('./Reports/CustomerUnlocksReport/customerUnlocksReport'),
        ),
        title: 'sidebar.customer.unlocksreport',
      },
      {
        path: '/whats-new',
        component: React.lazy(() => import('./WhatsNew/whatsNew')),
        title: 'sidebar.whats.new',
      },
      {
        path: '/api-requests',
        component: React.lazy(() =>
          import('./Approval/ManageApiKeys/manageApiKeys'),
        ),
        title: 'sidebar.apirequests.managekeys',
      },
      {
        path: '/approve',
        component: React.lazy(() => import('./Approval/approve')),
        title: 'sidebar.approval.approve',
      },
      {
        path: '/restore-customer-cart',
        component: React.lazy(() =>
          import('./CustomerCart/RestoreCustomerCart/restoreCustomerCart'),
        ),
        title: 'sidebar.customercart',
      },
      {
        path: '/to-do/:prospect_id',
        component: React.lazy(() => import('./Members/ToDo/toDo')),
        title: 'ToDo',
      },
      {
        path: '/support-ticket/:prospect_id',
        component: React.lazy(() => import('./Members/ToDo/toDo')),
        title: 'sidebar.support.view_ticket',
      },
      {
        path: '/main-site-banner-settings',
        component: React.lazy(() => import('./System/siteBannerSettings')),
        title: 'sidebar.systemsitebanner',
      },
      {
        path: '/support-ticket',
        component: React.lazy(() => import('./TicketSupport/ticketSupport')),
        title: 'sidebar.support.ticket',
      }
      // {
      //   path: '/zero_activity',
      //   component: React.lazy(() => import('./ZeroActivity/zeroActivity')),
      //   title: 'sidebar.zeroactivity',
      // },
    ],
  },
  /** end clepr code **/
];
