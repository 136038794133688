import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const customerAbuseCalculatedConfigs = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/top_abusers',
        component: React.lazy(() => import('./CustomerAbuseCalculated/customerAbuseCalculated')),
        title: 'Top Abusers',
      }
    ],
  }
];
