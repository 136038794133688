import axios from 'axios';

const jwtAxios = axios.create({
  //baseURL: 'https://crema-mongo-api.herokuapp.com/api/', //YOUR_API_URL HERE
  //baseURL: 'https://api2.lead411.com/api/v2/', //YOUR_API_URL HERE
  baseURL: 'https://team2-api.lead411.com/api/v2', //YOUR_API_URL HERE
  //baseURL: 'http://127.0.0.1:8000/api/v2/', //YOUR_API_URL HERE
  // baseURL: 'https://newteam2-api.lead411.com/api/v2/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  (res) => {
    //console.log(res.status);
    return res;
  },
  (err) => {
   
    if (err.response && err.response.statusText === 'Unauthorized') {       
      if(err.response.status === 401){
      }
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
