import React, {useState, useEffect} from 'react';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function ToastNotification(props) {
  
  const [open, setOpen] = useState(false);
  const [toastmessage, setToastmessage] = useState(null);
  const [toastclass, setToastclass] = useState('success');

  useEffect(() => {
    if(open === true){
      setTimeout(() => { setOpen(false)}, 8000);
    }
  }, [open]);
  
  useEffect(() => {
    const toastNotification = (response) => {
      if (typeof response === 'object' && response !== null) {
        if (response.status === true || response.success === true) {
          setToastmessage(response.message);
          setToastclass('success');
        } else {
          var obj = response.data;
          if (typeof obj === 'object') {
            errorMessages(obj, toastNotification);
          } else {
            toastNotification(response.message);
          }
        }
        setOpen(true);
      } else {
        setToastmessage(response);
        setToastclass('error');
        setOpen(true);
      }
    };

    const errorMessages = (obj, fn) => {
      const values = Object.values(obj);
      values.forEach((val) =>
        val && typeof val === 'object' ? errorMessages(val, fn) : fn(val),
      );
    };

    if (props.response !== null) {
      toastNotification(props.response);
    }
  }, [props]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {open ? (
        <Snackbar open={open} autoHideDuration={800} onClose={handleClose}>
          <Alert onClose={handleClose} severity={toastclass}>
            {toastmessage}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}
export default ToastNotification;
