import jwtAxios from './jwt-api';
import * as moment from 'moment';
import axios from 'axios';
// import {useDispatch, useSelector} from 'react-redux';
// import { onJWTAuthSignout } from '../../../redux/actions';

const CancelToken = axios.CancelToken; //this should always gloabl
let cancelSearchMemberObj = null; //this is function specific object but should be global
//so if you want to do same thing for other function add new variable here
//let cancelEditMemberObj=null;
var retry = 0;
let maxRetry = 2;
//searchForCustomerIdOnly will be customer id
export const searchMembers = async (searchString, searchForCustomerIdOnly = null) => {
  let timeoutMilli = 60000;
  let body = {}; 
    if(searchForCustomerIdOnly !== null){ //This block will execute when only customer id passed/ on member name click
        body = {filters: {fields: {customer_id: searchForCustomerIdOnly}}};      
    }else{

        body = {
            filters: {
                fields: {
                    first_name: searchString,
                    last_name: searchString,
                    email: searchString,
                    company: searchString,
                    company_url: searchString,
                    phone: searchString,
                    ip: searchString
                },
                wildcard: 'like',
                operator: 'OR',
            }
        };

        if(searchString.indexOf("cus_") > -1 && searchString?.length >= 5){
          body.filters.fields.stripe_id = searchString;
        }
    }

  try {
      
    if (cancelSearchMemberObj !== undefined && cancelSearchMemberObj !== null) {
      cancelSearchMemberObj();
    } //here you can change all these cancelSearchMemberObj variable with your object for other functions
    
    const res = await jwtAxios.post('searchMemberData', body, {
      cancelToken: new CancelToken(function executor(cToken) {
        cancelSearchMemberObj = cToken; //here also assign the value to your object when you use this in another function
      }),timeout: timeoutMilli });

    return res.data.data;
    
  } catch (err) {
      
        if(err.response && err.response.statusText === "Unauthorized"){
          delete jwtAxios.defaults.headers.common['Authorization'];
          localStorage.removeItem('token');
          window.location.reload();
        }
        
        //console.log(`${retry} < ${maxRetry}`);
        //console.log(`${err}`);
       
        if((err.message === "timeout of "+timeoutMilli+"ms exceeded" && retry < maxRetry) || (err.message === "Network Error")){
            console.log(err.message + " - After Timeout/Network Error new request");
            retry++;
            return searchMembers(searchString, searchForCustomerIdOnly);
        }
        
      //console.log(err.message);
      return err.message;
  }
};  
         
export const changePassword = async (customer_id, newpassword) => {
  const body = {
    customer_id: customer_id,
    password: newpassword,
  };

  return callAPI('changePassword', body);
};

export const CancelSubscriptionOnNextUpdate = async (customer_id) => {
  const body = {
    customer_id: customer_id,
  };
  return callAPI('cancelOnUpdate', body);
};

export const CancelSubscriptionImmediately = async (customer_id) => {
  const body = {
    customer_id: customer_id,
  };
  return callAPI('cancelSubscription', body);
};

export const convertLeadToContactSF = async( payload ) => {
  return callAPI('convert_lead_to_contact_SF', payload);
}

export const CancelSubscriptionCancelOnDate = async (
  customer_id,
  canceldate,
) => {
  const body = {
    customer_id: customer_id,
    popupDatepicker: moment(canceldate).format('MM/DD/YYYY'),
  };
  return callAPI('cancelOnUpdate', body);
};

export const stopFutureCancelation = async (customer_id) => {
  const body = {customer_id: customer_id};
  return callAPI('stopFutureCancelation', body);
};

export const updateMainSiteBannerData = async (payload) => {
  return callAPI('update_main_site_banner_data', payload);
};

export const getCountries = async (customer_id) => {
  const body = {customer_id: customer_id};
  return callAPI('get_countries', body);
};

export const updateMemberResignData = (payload) => {

  return function(dispatch) {
    return callAPI('update_member_resign_data', payload);
  }
  
};
export const update_member_end_subscription_data = (payload) => {

  return function(dispatch) {
    return callAPI('update_member_end_subscription_data', payload);
  }
  
};

export const updateEncription = (payload) => {
  return callAPI('update_encription_status', payload);  
};

export const markCompleteZeroActivity = (payload) => {

  return function(dispatch) {
    return callAPI('mark_complete_zero_activity', payload);
  }
  
}

export const markIgnoreZeroActivity = (payload) => {

  return function(dispatch) {
    return callAPI('mark_ignore_zero_activity', payload);
  }
  
}

export const stopResignCadence = (payload) => { 
  return function(dispatch) { 
    return callAPI('stop_resign_cadence', payload);
  }
};

export const AllSubMembers = async (customer_id) => {
  const body = {
    customer_id: customer_id,
  };
  return callAPI('getSubMembers', body);
};

export const companyDataValidation = async (url, payload) => {

  let timeoutMilli = 60000;
  try {
      
    if (cancelSearchMemberObj !== undefined && cancelSearchMemberObj !== null) {
      cancelSearchMemberObj();
    } //here you can change all these cancelSearchMemberObj variable with your object for other functions
    
    const res = await jwtAxios.post(url, payload, {
      cancelToken: new CancelToken(function executor(cToken) {
        cancelSearchMemberObj = cToken; //here also assign the value to your object when you use this in another function
      }),timeout: timeoutMilli });

    return res.data;
    
  } catch (err) {
      return {}
  }
  
  //return callAPI(url, payload);

};

export const enableTeamAccountWithseatsCheck = async (
  id,
  status,
  customer_id,
) => {
  const body = {
    customer_id: customer_id,
    status: status,
    id: id,
  };
  return callAPI('enableTeamAccountWithseatsCheck', body);
};

export const enableMemberAccount = async (
  customer_id,
) => {
  const body = {
    customer_id: customer_id
  };
  return callAPI('enable_member_account', body);
};

export const updateNewSiteRedirect = async (customer_id, status) => {
  const body = {
    status: status,
    id: customer_id,
  };
  return callAPI('updateNewSiteRedirect', body);
};

export const getMembersAlertsAndTriggers = async (customer_id) => {
  const body = {
    customer_id: customer_id,
  };
  return callAPI('getMembersAlertsAndTriggers', body);
};
export const copyNews = async (
  customerid,
  customer_alert_id,
  toYouOrCustomer,
) => {
  const body = {
    customer_id: customerid,
    customer_alert_id: customer_alert_id,
    to_you_or_customer: toYouOrCustomer,
  };

  return callAPI('copyNews', body);
};
/** update member info permissions, seats, bonus quota, unlock quota etc */
export const saveMemberDetailsMoreInfo = async (payload) => {
  return callAPI('saveMemberDetailsMoreInfo', payload);
};

export const show_customer_history_record = async (customer_id) => {
  return callAPI('show_customer_history_record/' + customer_id, '');
};
/** Update customer basic details */
export const editMemberDetails = async (payload) => {
  return callAPI('editMemberDetails', payload);
};

/** Restore customer permissions */
export const restoreMemberPermission = async (payload) => {
  return callAPI('restoreMemberPermission', payload);
};

export const processCSVDraftingCustomer = async (payload) => {
  return callAPI('processCSVDraftingCustomer', payload);
};

export const updateAlertStatus = async (payload) => {
  return callAPI('update_alert_status', payload);
};

export const check_is_more_emails_than_accounts = async (payload) => {
  return callAPI('check_is_more_emails_than_accounts', payload);
};

/** Restore customer permissions */
export const updateBomboraIntentTopics = async (payload) => {
  return callAPI('updateBomboraIntentTopics', payload);
};

/** Restore customer permissions */
export const clearAllBomboraIntentTopics = async (payload) => {
  return callAPI('clearAllBomboraIntentTopics', payload);
};

export const searchMemberData_id = async (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };

  return callAPI('searchMemberData_id', payload);
};

export const renameTrigger = async (payload) => {
  return callAPI('renameTrigger', payload);
};

export const updateCustomerTrial = async (payload) => {
  return callAPI('updateCustomerTrial', payload);
};

export const createSubAccountRegister = async (payload) => {
  return callAPI('sub-account/register', payload);
};

export const agentChangePassword = async (payload) => {
  return callAPI('agent/changePassword', payload);
};

export const Tracker = async (endpoint, payload) => {
  return callAPI(endpoint, payload);
};

export const deleteTriggerApi = async (trigger_id, customer_id, fromWhom) => {
  var payload = {
    id: trigger_id,
    customer_id: customer_id,
    fromWhom: fromWhom,
  };
  return callAPI('deleteTrigger', payload);
};

export const CopyTrigger = async (trigger_id, customer_id, fromWhom) => {
  var payload = {
    id: trigger_id,
    customer_id: customer_id,
    fromWhom: fromWhom,
  };
  return callAPI('copyTrigger', payload);
};

export const blockCc = async (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('blockCc', payload);
};

export const blockCustomer = async (customer_id, block_all = "" ) => {
  var payload = {
    customer_id: customer_id,
  }

  if(block_all !== ""){
    payload.block_all = true;
  }

  return callAPI('blockCustomer', payload);
};

export const updateCompanyFlag = async (payload) => {
  return callAPI('update_company_correct_data', payload);
}

export const ignoreCompanyFlag = async (payload) => {
  return callAPI('ignore_company_flag', payload);
}

export const updateEmployeeFlag = async (payload) => {
  return callAPI('update_employee_correct_data', payload);
}

export const ignoreEmployeeFlag = async (payload) => {
  return callAPI('ignore_employee_flag', payload);
}

export const getEmployeeEventsData = async (customer_id) => {
  return callAPI('get_employee_events_data', { customer_id: customer_id });
}

export const getFingerprintData = async (payload) => {
  return callAPI('get_fingerprint_data', payload);
}

export const exportMasterMembersData = async (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('exportMasterMembersData', payload);
};

export const customerLoginCheck = async (payload) => {
  return callAPI('customer/login', payload);
};

export const getCustomerLogs = async (uri, payload) => {
  return callAPI(uri, payload);
};

export const LinkCustomerWithAgent = async (customer_email) => {
  var payload = {
    customer_email: customer_email,
  };
  return callAPI('linkCustomerIdWithAgent', payload);
};

export const showUnlockCustomerExports = async (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('showUnlockCustomerExports', payload);
};

export const ownTriggerlist = () => {
  return callAPI('ownTriggerlist', '');
};

export const quickStatsData = () => {
  return callAPI('stats/quickstats', '');
};
export const CompanyEmpCount = () => {
  return callAPI('counts', '');
};

export const getStripeStats = () => {
  return callAPI('getStripeStats', '');
};

export const GetDataBasedOnPayloadAndUrl = (endpoint, payload) => {
  return callAPI(endpoint, payload);
};

export const GetDataBasedOnPayloadAndUrlWithCancelToken = async (endpoint, payload) => {

  let timeoutMilli = 600000;
  try {
      
    if (cancelSearchMemberObj !== undefined && cancelSearchMemberObj !== null) {
      cancelSearchMemberObj();
    } //here you can change all these cancelSearchMemberObj variable with your object for other functions
    
    const res = await jwtAxios.post(endpoint, payload, {
      cancelToken: new CancelToken(function executor(cToken) {
        cancelSearchMemberObj = cToken; //here also assign the value to your object when you use this in another function
      }),timeout: timeoutMilli });

    return res.data;
    
  } catch (err) {
      return {}
  }
  
};

export const deleteGDriveCsvRecord = (payload) => {
  return callAPI("delete_gdrive_csv_record", payload);
};

export const UpdateScannedDomainCompanyData = async (payload) => {
   
  return callAPI('updateScannedDomainCompanyData', payload);
};

export const ExportCustomerData = (endpoint, payload) => {
  return callAPI(endpoint, payload);
};

export const AddBlockedDomain = (payload) => {
  return callAPI('add_blocked_domain', payload);
};
export const DeleteInsideTechnology = (id) => {
  var payload = {
    delete_inside_technology_id: id,
  };
  return callAPI('delete_inside_technology', payload);
};
export const DeleteOutsideTechnology = (id) => {
  var payload = {
    delete_outside_technology_id: id,
  };
  return callAPI('delete_outside_technology', payload);
};

export const DeleteBlockedDomain = (domainToBeDeleted) => {
  var payload = {
    delete_blocked_domain: domainToBeDeleted,
  };

  return callAPI('delete_blocked_domain', payload);
};

export const HideUnlocks = (record_id) => {
  var payload = {
    id: record_id,
  };

  return callAPI('hide_unlocks', payload);
};

export const RemoveCustomerAbuseData = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('remove_customer_abuse_data', payload);
};

export const RemoveCustomerAbused = (customer_id, fingerprint_id, lastXDays) => {
  var payload = {
    customer_id: customer_id,
    fingerprint_id: fingerprint_id,
    last_x_days: lastXDays
  }
  
  return callAPI('remove_customer_abused', payload);
};

export const CustomerAbuseDetails = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('customer_abuse_details', payload);
};

export const getCustomerStats = (URI, payload) => {
  // console.log(customer_id, " >>>>>>> ");
  // var payload = {
  //   customer_id: customer_id,
  // };
  return callAPI(URI, payload);
};

/** Manage api keys **/
export const RequestApprove = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('requestApprove', payload);
};

export const RevokeRequest = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('revoke_request', payload);
};

export const updateAccountStanding = ( payload ) => {
  return callAPI("customer_account_update", payload);
};
/******************/

/*** Approval ***/
export const EnterpriseRevokeRequest = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('enterprise_revoke_request', payload);
};
export const EnterpriseApproveRequest = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('enterprise_approve_request', payload);
};

export const SbxxxApproveRequest = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('sbxxx_approve_request', payload);
};
export const ApproveAuthenticate = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('approve_authenticate', payload);
};

export const sendWelcomeMail = (customer_id, template) => {

  var payload = {
    customer_id: customer_id,
    template: template
  };

  return callAPI('send_welcome_email', payload);

}

export const RestoreCart = (customer_id) => {
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('restore_cart', payload);
};
export const UserTodo = (prospect_id) => {
  var payload = {
    prospect_id: prospect_id,
  };
  return callAPI('userTodo', payload);
};

export const UpdateTaskStatus = (task_id, status) => {
  var payload = {
    status: status,
    task_id: task_id,
  };
  return callAPI('update_task_status', payload);
};

export const UpdateAgentPermissions = (agent_id, permissions) => {
  var payload = {
    agent_id: agent_id,
    permissions: permissions,
  };
  return callAPI('update_agent_permissions', payload);
};

export const GetAgentById = (agent_id) => {
  return callAPI('agentById/' + agent_id, {});
};

export const UpdateSupportNotifications = (agent_id) => {
  var payload = {
    reader_agent_id: agent_id,
  };
  return callAPI('update_support_notifications', payload);
};

export const UpdateCustomerSPAM = (customer_id) => {
    
  var payload = {
    customer_id: customer_id,
  };
  return callAPI('updateCustomerSPAM', payload);
};

export const TestNewSiteUpdate = ( customer_id, status ) => {
    
  var payload = {
    customer_id: customer_id,
    status: status
  };
  return callAPI('updateTestNewSite', payload);
};

export const TestNewSiteUpdateTeamAccount = ( parent_id, status ) => {
    
  var payload = {
    parent_id: parent_id,
    status: status
  };
  return callAPI('TestNewSiteUpdateTeamAccount', payload);
};

export const getGmailSettings = () => {
  return callAPI('get_gmail_settings');
}

export const getAuthorizeToken = ( payload ) => {

  return callAPI('get_authorize_token', payload);
  
}

export const updateSubscriptionDate = ( payload ) => {

  return callAPI('update_subscription_date', payload);
  
}

export const refreshToken = () => {

  return callAPI( 'refresh_gmail_token' );
  
}

export const getEmailInbox = ( payload ) => {

  return callAPI( 'getEmailInbox', payload );
  
}

export const getAuthorizedAccounts = () => {

  return callAPI( 'get_athorized_accounts' );
  
}

export const deleteGmailAuthAccount = ( payload ) => {

  return callAPI( 'deleteGmailAuthAccount', payload );
  
}

export const RegisterGoogleUser = (googleLoggedInUser) => {

  var googleUserId = googleLoggedInUser.googleId; //114481441738619216344
  var tokenId = googleLoggedInUser.tokenId; //114481441738619216344
  var tokenObj = googleLoggedInUser.tokenObj; //114481441738619216344
  var profileObj = googleLoggedInUser.profileObj; 
  var email = profileObj.email;
  var fullName = profileObj.name;
  var familyName = profileObj.familyName;
  var givenName = profileObj.givenName;
  var password = googleUserId.slice(0, 6);
  var payload = {
                  "name": fullName,
                  "email": email,
                  "password": password,
                  "first": givenName,
                  "last": familyName,
                  "level": "sales",
                  "google_user_id": googleUserId
                }

  /* response profileObj:
  email: "singh8ss@gmail.com"
  familyName: "Singh"
  givenName: "Balwinder"
  googleId: "114481441738123123123"
  imageUrl: "https://lh3.googleusercontent.com/a-/AOh14Gh46XqBAzZNqjyJEFTVxri9t8DpGgJVj2kdHvZLgg=s96-c"
  name: "Balwinder Singh"*/

  return callAPI("/social_login", payload);

};

async function callAPI(endpoint, body) {
    retry = 0;
    return await APITrigger(endpoint, body);
}

async function APITrigger(endpoint, body){
    
    let timeoutMillisec = 60000;
    try {
        const res = await jwtAxios.post(endpoint, body, {timeout: timeoutMillisec});
        return res.data;
      } catch (err) {
        if(err.response && err.response.statusText === "Unauthorized"){
          delete jwtAxios.defaults.headers.common['Authorization'];
          localStorage.removeItem('token');
          window.location.reload();
        }
        
        //console.log(err.message);
        
        if((err.message === "timeout of "+timeoutMillisec+"ms exceeded") || (err.message === "Network Error") && retry < maxRetry){
            console.log(retry +" < "+ maxRetry);
            retry++;
            return APITrigger(endpoint, body);
        }
        return err.message;
     }  
}

async function GetAPITrigger(endpoint, body){
    
  let timeoutMillisec = 60000;
  try {
      const res = await jwtAxios.get(endpoint, { params: body });

      return res.data;
    } catch (err) {

      if(err.response && err.response.statusText === "Unauthorized"){
        delete jwtAxios.defaults.headers.common['Authorization'];
        localStorage.removeItem('token');
        window.location.reload();
      }
            
      if((err.message === "timeout of "+timeoutMillisec+"ms exceeded") || (err.message === "Network Error") && retry < maxRetry){
          retry++;
          return GetAPITrigger(endpoint, body);
      }
      return err.message;
   }  
}

export function searchCompanyUrl(endpoint, body){    
  return GetAPITrigger(endpoint, body);  
}

export function getMainSiteSettings(endpoint, body){    
  return GetAPITrigger(endpoint, body);  
}

export function SaveUpdateCompany(endpoint, body){    
  return APITrigger(endpoint, body);  
}

export function SaveUpdateEmployee(endpoint, body){    
  return APITrigger(endpoint, body);  
}

export function AddUpdateCompanyPageData(endpoint, body){    
  return APITrigger(endpoint, body);  
}

export function postFile(body){    
  return APITrigger("post_file", body);  
}
export function getCompanyEmployees(URI, body){    
  return APITrigger(URI, body);  
}
export function deleteEmployee(body){    
  return APITrigger("deleteEmployee", body);  
}

export function banIp(body){    
  return APITrigger("banCustomerIp", body);  
}

export function clearCartData(body){    
  return APITrigger("clearCartData", body);  
}
export function getAllOrdersWithIndexes(body){    
  return APITrigger("getAllOrdersWithIndexes", body);  
}
export function reloadCart(body){    
  return APITrigger("reloadOrderCart", body);  
}

export function cartItems(body){    
  return APITrigger("cart_items", body);  
}


export function insertGmailCsv(body){    
  return APITrigger("insert_gmail_csv", body);  
}

export function deleteMultipleEmployee(body){    
  return APITrigger("deleteSelectedEmployeeRecords", body);  
}
export function deletePress(body){    
  return APITrigger("deletePress", body);  
}

export function deleteStagedPress(body){    
  return APITrigger("deleteStagedPress", body);  
}

export function getPressData(URI, body){    
  return APITrigger("viewEditPress", body);  
}

export function getStagedPressData(URI, body){    
  return APITrigger(URI, body);  
}

export function searchPressUrl( body ){    
  return APITrigger("searchPressURL", body);  
}

export function unAssignCompany( body ){    
  return APITrigger("un_assign_company_update", body);  
}
export function assignCompanyForUpdate( body ){    
  return APITrigger("assign_company_for_update", body);  
}

export function getPressTeamplateData(body){    
  return APITrigger("getPressTeamplateData", body );  
}
export function addPress( body ){    
  return APITrigger( "addPress", body );  
}
export function allPressData( body ){    
  return APITrigger( "showPressList", body );  
}

export function searchEmployeeForPressPage( body ){    
  return APITrigger( "searchEmployeeForPressPage", body );  
}
export function searchStateByValue( body ){    
  return GetAPITrigger( "search/searchState", body );  
}
export function searchCompanyURL( body ){    
  return GetAPITrigger( "search/searchCompanyURL", body );  
}
export function titleSuggestion( body ){    
  return GetAPITrigger( "search/titleSuggestion", body );  
}

export function saveEditPress( body ){    
  return APITrigger( "save_edit_press", body );  
}

export function insertStoragePress( body ){    
  return APITrigger( "insertStoragePress", body );  
}

export function searchCompany_update( uri, body ){    
  return APITrigger( uri, body );  
}
export function update_searchCompany_emp_size( body ){    
  return APITrigger("update_searchCompany_emp_size", body );  
}

export function researchEmailBrightVerfy(endpoint, body){    
  return APITrigger(endpoint, body);  
}

export function APICall(endpoint, body){    
  return APITrigger(endpoint, body);  
}

/******************Enrichment API *****************/

export function updateCustomerStatus(payload){
  return callAPI('enrichment/update_customer_status', payload);
}

export function getUserPreviousMonthUsage(endpoint, payload){
  return callAPI(endpoint, payload);
}