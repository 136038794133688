import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from '../../shared/constants/AppConst';

export const appsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/mail/:id/compose',
        component: React.lazy(() => import('./Mail')),
      },
      {
        path: '/mail/:id/label/:name/:messageId?',
        component: React.lazy(() => import('./Mail')),
      },
      {
        path: '/apps/mail',
        component: () => <Redirect to='/apps/mail/folder/inbox' />,
      }
    ],
  },
];
