import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const agentConfigs = [
  /**clepr code **/
  {
    // auth: ['user'],
    // role: ['admin'],
    auth: authRole.user,
    routes: [
      {
        path: '/agents',
        component: React.lazy(() => import('./Agent/agentList')),
        title: 'Agents',
      },
      {
        path: '/edit-agent',
        component: React.lazy(() => import('./Agent/editAgent')),
        title: 'Edit Agent',
      },
    ],
  },
  /** end clepr code **/
];
