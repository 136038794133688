import {
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  FETCH_ERROR
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';

export const onJwtUserSignUp = ({email, password, name}) => {
  console.log('email, password', {email, password, name});
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, name, password};
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = ({email, password}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, password};
    try {
      const res = await jwtAxios.post('login', body);
      localStorage.setItem('token', res.data.data.token);
      dispatch(setJWTToken(res.data.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      console.log('error!!!!', err.message);
      dispatch(fetchError(err.message));
    }
  };
};

export const onSocialSignIn = (userData) => {
  return async (dispatch) => {
    try {      
      localStorage.setItem('token', userData.data.token);
      dispatch(setJWTToken(userData.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      console.log('error!!!!', err.message);
      dispatch(fetchError(err.message));
    }
  }
 
};

export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('/user');
      const ticketsCount = await jwtAxios.post('/today_tasks_count');

      dispatch(fetchSuccess());
      let ticketsCounts = ticketsCount.data ? ticketsCount.data : 0;
      let user = res.data.data.user;

      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: AuthType.JWT_AUTH,
          userId: user.agent_id,
          displayName: user.first + ' ' + user.last,
          email: user.email,
          token: localStorage.token,
          photoURL: '',
          permissions: user.permissions,
          role: user.level,
          ticketCounts: ticketsCounts,
        },
      });
    } catch (err) {
      console.log('error!!!!', err);
      dispatch(fetchError(err));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch({type: FETCH_SUCCESS});
      localStorage.removeItem('token');
    }, 500);
  };
};


export const onResetPassword = (email, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('sendResetPasswordEmail', {email: email})
      .then((data) => {
        dispatch({type: FETCH_SUCCESS});
        if (data.data.success) {
          history.push('/user/forgot-password-2', {email: email});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.data.data});
        }
      })
      .catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
